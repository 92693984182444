import { useContext, useEffect } from "react";
import { Navigate, Routes } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

export default function LogoutScreen() {
  const {
    auth: { logout },
  } = useContext(AuthContext);

  useEffect(() => {
    logout();
    document.location.reload();
  }, []);

  return (
    <Routes>
      <Navigate to="/auth/login" />
    </Routes>
  );
}
