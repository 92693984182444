import DatePicker, { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import AppIcon from "../AppIcon";
registerLocale("tr", tr);

const DateTimePickerInput = forwardRef(({ value, onClick }, ref) => (
  <div
    className="h-10 px-3 border border-gray-300 flex items-center justify-between cursor-pointer"
    onClick={onClick}
    ref={ref}
  >
    <span className="block text-sm">{value}</span>
    <AppIcon code="fa-calendar" className="h-5 w-5" />
  </div>
));

const _getFormat = (format, time = false) => {
  let newFormat = format?.split(" ")[0]?.replace("YYYY", "yyyy").replace("DD", "dd");
  return time ? `${newFormat} HH:m:s` : newFormat;
};

export default function AppDateTimePicker({ form, field, time = false, ...props }) {
  const { PM } = useContext(AppContext);
  return (
    <div {...props}>
      <DatePicker
        disabled={props.disabled}
        selected={field.value ? new Date(field.value) : new Date()}
        onChange={(value) =>
          form.setFieldValue(field.name, time ? value.toISOString() : value.toISOString().split("T")[0])
        }
        locale="tr"
        showTimeSelect={time}
        customInput={<DateTimePickerInput value={field.value} />}
        dateFormat={_getFormat(PM.profile?.default?.dateFormat ?? "yyyy-MM-dd h:m:s", time)}
      />
    </div>
  );
}
