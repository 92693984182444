import { useContext } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LogoutScreen from "../components/screens/LogoutScreen";
import { AuthContext } from "../context/AuthContext";
import AuthPage from "../modules/auth/AuthPage";
import PrivateRoutes from "./PrivateRoutes";

export default function AppRoutes() {
  const {
    auth: { isLogedIn },
  } = useContext(AuthContext);
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="logout" element={<LogoutScreen />} />
        {isLogedIn ? (
          <>
            <Route path="/*" element={<PrivateRoutes />} />
            <Route index element={<Navigate to="/users" />} />
          </>
        ) : (
          <>
            <Route path="auth/*" element={<AuthPage />} />
            <Route path="*" element={<Navigate to="/auth" />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}
