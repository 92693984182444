import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import SuspensedView from "./SuspensedView";
import MiniSideBarLayout from "../layouts/MiniSideBarLayout";

export default function RouteContainer({ paths, layout = <Outlet /> }) {
  return (
    <Routes>
      <Route element={layout}>
        {paths.map(({ path, element }, _index) => (
          <Route key={_index} path={path} element={<SuspensedView>{element}</SuspensedView>} />
        ))}
      </Route>
      <Route path="*" element={<Navigate to="/error/404" />} />
    </Routes>
  );
}
