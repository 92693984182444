import { Outlet } from "react-router-dom";
import Header from "./Header";

export default function MasterLayout({ children }) {
  return (
    <>
      <div className="flex h-[100vh] bg-neutral-100 overflow-hidden flex-col">
        <Header />
        <div className="flex h-[100vh] min-w-0 flex-1 flex-col relative lg:order-last">
          <Outlet />
        </div>
      </div>
    </>
  );
}
