import AppButton from "@components/AppButton";
import { useContext } from "react";
import { AuthContext } from "src/context/AuthContext";

export default function UserMenu() {
  const {
    auth: { logout },
  } = useContext(AuthContext);
  return <AppButton title="T.SIGNOUT" mode="danger" onClick={() => logout()} />;
}
