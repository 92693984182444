import {
  ArrowPathIcon,
  ArrowsUpDownIcon,
  BellAlertIcon,
  BellIcon,
  CheckBadgeIcon,
  CloudArrowDownIcon,
  CloudArrowUpIcon,
  EllipsisVerticalIcon,
  FaceSmileIcon,
  MapIcon,
  MapPinIcon,
  MicrophoneIcon,
  MinusIcon,
  PaperAirplaneIcon,
  PaperClipIcon,
  PencilIcon,
  PhoneIcon,
  PlusCircleIcon,
  PlusIcon,
  ShareIcon,
  SparklesIcon,
  TrashIcon,
  XMarkIcon,
  StopCircleIcon,
  PauseCircleIcon,
} from "@heroicons/react/20/solid";
import { FunnelIcon, MinusCircleIcon, WifiIcon } from "@heroicons/react/24/outline";
import { lazy, Suspense } from "react";

const ExclamationCircleIcon = lazy(() => import("@heroicons/react/24/outline/ExclamationCircleIcon"));
const ExclamationTriangleIcon = lazy(() => import("@heroicons/react/24/outline/ExclamationTriangleIcon"));
const EyeIcon = lazy(() => import("@heroicons/react/24/outline/EyeIcon"));
const ChatBubbleLeftRightIcon = lazy(() => import("@heroicons/react/24/outline/ChatBubbleLeftRightIcon"));
const ChartBarIcon = lazy(() => import("@heroicons/react/24/outline/ChartBarIcon"));
const UsersIcon = lazy(() => import("@heroicons/react/24/outline/UsersIcon"));
const ChevronDownIcon = lazy(() => import("@heroicons/react/24/outline/ChevronDownIcon"));
const ArrowLeftIcon = lazy(() => import("@heroicons/react/24/outline/ArrowLeftIcon"));
const ArrowRightIcon = lazy(() => import("@heroicons/react/24/outline/ArrowRightIcon"));
const ArrowsRightLeftIcon = lazy(() => import("@heroicons/react/24/outline/ArrowsRightLeftIcon"));
const Bars3Icon = lazy(() => import("@heroicons/react/24/outline/Bars3Icon"));
const BellSlashIcon = lazy(() => import("@heroicons/react/24/outline/BellSlashIcon"));
const BookmarkIcon = lazy(() => import("@heroicons/react/24/outline/BookmarkIcon"));
const BookmarkSquareIcon = lazy(() => import("@heroicons/react/24/outline/BookmarkSquareIcon"));
const BookOpenIcon = lazy(() => import("@heroicons/react/24/outline/BookOpenIcon"));
const CalendarIcon = lazy(() => import("@heroicons/react/24/outline/CalendarIcon"));
const CheckCircleIcon = lazy(() => import("@heroicons/react/24/outline/CheckCircleIcon"));
const CheckIcon = lazy(() => import("@heroicons/react/24/outline/CheckIcon"));
const ChevronLeftIcon = lazy(() => import("@heroicons/react/24/outline/ChevronLeftIcon"));
const ChevronRightIcon = lazy(() => import("@heroicons/react/24/outline/ChevronRightIcon"));
const ChevronUpIcon = lazy(() => import("@heroicons/react/24/outline/ChevronUpIcon"));
const CircleStackIcon = lazy(() => import("@heroicons/react/24/outline/CircleStackIcon"));
const ClipboardDocumentIcon = lazy(() => import("@heroicons/react/24/outline/ClipboardDocumentIcon"));
const ClockIcon = lazy(() => import("@heroicons/react/24/outline/ClockIcon"));
const CogIcon = lazy(() => import("@heroicons/react/24/outline/CogIcon"));
const InformationCircleIcon = lazy(() => import("@heroicons/react/24/outline/InformationCircleIcon"));
const HomeIcon = lazy(() => import("@heroicons/react/24/outline/HomeIcon"));

const _FAHERO = {
  "fa-address-book": BookmarkIcon,
  "fa-arrow-left": ArrowLeftIcon,
  "fa-arrow-right": ArrowRightIcon,
  "fa-arrows-alt": ArrowsRightLeftIcon,
  "fa-bars": Bars3Icon,
  "fa-bell-slash": BellSlashIcon,
  "fa-bell": BellAlertIcon,
  "fa-book": BookOpenIcon,
  "fa-bookmark": BookmarkIcon,
  "fa-calendar": CalendarIcon,
  "fa-caret-down": ChevronDownIcon,
  "fa-caret-right": ChevronRightIcon,
  "fa-check": CheckIcon,
  "fa-chart": ChartBarIcon,
  "fa-check-circle": CheckCircleIcon,
  "fa-chevron-down": ChevronDownIcon,
  "fa-chevron-left": ChevronLeftIcon,
  "fa-chevron-right": ChevronRightIcon,
  "fa-chevron-up": ChevronUpIcon,
  "fa-circle": MinusCircleIcon,
  "fa-clock": ClockIcon,
  "fa-cog": CogIcon,
  "fa-coins": CircleStackIcon,
  "fa-compass": FunnelIcon,
  "fa-copy": ClipboardDocumentIcon,
  "fa-drag": ArrowsUpDownIcon,
  "fa-dashboard": HomeIcon,
  "fa-directions": "",
  "fa-dot-circle": "",
  "fa-download": "",
  "fa-ellipsis-v": EllipsisVerticalIcon,
  "fa-envelope": "",
  "fa-exclamation": "",
  "fa-exclamation-circle": ExclamationCircleIcon,
  "fa-exclamation-triangle": ExclamationTriangleIcon,
  "fa-eye": EyeIcon,
  "fa-export": CloudArrowDownIcon,
  "fa-file-word": "",
  "fa-filter": FunnelIcon,
  "fa-folder-open": "",
  "fa-genderless": "",
  "fa-globe": "",
  "fa-handshake": "",
  "fa-heartbeat": "",
  "fa-history": "",
  "fa-hotel": "",
  "fa-paperclip": "",
  "fa-info-circle": InformationCircleIcon,
  "fa-link": "",
  "fa-list": "",
  "fa-lock": "",
  "fa-map": MapIcon,
  "fa-map-marker-alt": MapPinIcon,
  "fa-mobile-alt": "",
  "fa-paper-plane": "",
  "fa-pen": PencilIcon,
  "fa-phone": PhoneIcon,
  "fa-phone-slash": "",
  "fa-plus": PlusIcon,
  "fa-plus-circle": PlusCircleIcon,
  "fa-print": "",
  "fa-receipt": "",
  "fa-sad": "",
  "fa-save": "",
  "fa-shapes": "",
  "fa-share": ShareIcon,
  "fa-shield-alt": "",
  "fa-shuttle-van": "",
  "fa-sms": ChatBubbleLeftRightIcon,
  "fa-star": "",
  "fa-stopwatch": "",
  "fa-sync": ArrowPathIcon,
  "fa-spinner": ArrowPathIcon,
  "fa-thermomerter-half": "",
  "fa-thermometer-half": "",
  "fa-thermoter-half": "",
  "fa-thumbs-down": "",
  "fa-thumbs-up": "",
  "fa-thumbtack": "",
  "fa-times": XMarkIcon,
  "fa-tis": "",
  "fa-tis -9": "",
  "fa-tis-circle": "",
  "fa-trash": TrashIcon,
  "fa-unlink": "",
  "fa-upload": CloudArrowUpIcon,
  "fa-user": "",
  "fa-user-circle": "",
  "fa-users": UsersIcon,
  "fa-vector-square": "",
  "fa-video": "",
  "fa-whatsapp": "",
  "fa-plus": PlusIcon,
  "fa-minus": MinusIcon,
  "fa-smile": FaceSmileIcon,
  "fa-microphone": MicrophoneIcon,
  "fa-paper-plane": PaperAirplaneIcon,
  "fa-paperclip": PaperClipIcon,
  "fa-stop": StopCircleIcon,
  "fa-pause": PauseCircleIcon,
  "fa-coin": CircleStackIcon,
};

export default function AppIcon({ code = "", className = "", ...props }) {
  if (!code || !_FAHERO[code]) return <></>;
  const Item = _FAHERO[code];
  return (
    <Suspense fallback={<></>}>
      <Item className={className} {...props} />
    </Suspense>
  );
}
