import toast from "../helpers/toast";
import axios from "axios";

const _initiate = () => {
  const locale = localStorage.getItem("locale") ? JSON.parse(localStorage.getItem("locale"))?.selectedLang : "en";
  axios.defaults.headers.Accept = "application/json";
  axios.defaults.headers["App-Locale"] = locale;
  axios.interceptors.response.use(
    (response) => {
      if (response.headers["content-type"] !== "application/json; charset=utf-8") return response;
      if (response.data.status !== 200) {
        const messages = response.data.error?.messages;
        if (messages?.length > 0) messages.map((message) => toast.warning(message));
        throw response.data.error;
      }
      return response.data.data;
    },
    (error) => {
      toast.warning("API Comunication Error");
      console.log(error);
      throw "API Communication Error";
    }
  );
};

const _authorize = (token) => {
  axios.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (err) => Promise.reject(err)
  );
};

const _axios = { _initiate, _authorize };
export default _axios;
