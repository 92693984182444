import RouteContainer from "@components/core/RouteContainer";
import MasterLayout from "@components/layouts/MasterLayout";
import { lazy } from "react";
import { Navigate } from "react-router-dom";

const Users = lazy(() => import("@modules/users/Users"));

const paths = [
  { path: "/auth/*", element: <Navigate to="/dashboard" /> },
  { path: "/dashboard/*", element: <Users /> },
  { path: "/users/*", element: <Users /> },
];

export default function PrivateRoutes() {
  return <RouteContainer paths={paths} layout={<MasterLayout />} />;
}
