import { QueryClient, QueryClientProvider } from "react-query";
import { AppConfigProvider } from "./context/AppContext";
import I18nProvider from "./i18n/I18nProvider";
import { AuthProvider } from "./context/AuthContext";
import AppRoutes from "./routes/AppRoutes";
import { ToastContainer } from "react-toastify";

// Global Styles
import "./index.css";
import "react-toastify/dist/ReactToastify.css";

export default function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppConfigProvider>
          <I18nProvider>
            <AppRoutes />
            <ToastContainer />
          </I18nProvider>
        </AppConfigProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}
