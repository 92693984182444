import { Field } from "formik";
import { useIntl } from "react-intl";
import AppInput from "./AppInput";
import AppIcon from "../AppIcon";
import clx from "classnames";
export default function RowInput({ name, label, icon, guide, error, component = AppInput, placeholder, required, ...props }) {
  const intl = useIntl();

  return (
    <div className={`grid grid-cols-1 md:grid-cols-3 gap-x-2 ${props.className}`}>
      <div className="flex flex-col justify-center">
        {label && (
          <label className="block text-sm whitespace-nowrap text-gray-500 font-semibold">
            {icon && <AppIcon code={icon} />}
            {intl.formatMessage({ id: label ?? "-" })}
            {required && <span className="text-red-500 ml-1">*</span>}
          </label>
        )}
        {error && <span className="block text-sm text-red-500">{intl.formatMessage({ id: error })}</span>}
        {!error && guide && <span className="block text-sm text-gray-500">{intl.formatMessage({ id: guide })}</span>}
      </div>
      <div className={clx({ "col-span-2": label, "col-span-full": !label })}>
        <Field
          name={name}
          placeholder={intl.formatMessage({ id: Boolean(placeholder) ? placeholder : name ?? "-" })}
          component={component}
          autoComplete="off"
          {...props}
        />
      </div>
    </div>
  );
}
