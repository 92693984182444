import { API_BASE_URL } from ".";
const ADMIN = `${API_BASE_URL}/admin`;

const ENDPOINTS = {
  auth: {
    login: `${ADMIN}/auth/login`,
    verify: `${ADMIN}/auth/verify`,
  },
  user: `${ADMIN}/users`,
};

export default ENDPOINTS;
