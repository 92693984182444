import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import UserMenu from "../UserMenu";

export default function DesktopHeader() {
  const intl = useIntl();
  const MENUS = useMemo(() => [{ title: "Users", path: "/users" }], []);
  return (
    <div className="hidden md:flex md:min-w-0 md:flex-1 md:items-center md:justify-between px-4">
      <nav aria-label="Global" className="min-w-0 flex-1 flex items-center space-x-10">
        {MENUS.map(({ title, path }, _index) => (
          <Link to={path} key={_index} className="text-sm">
            {intl.formatMessage({ id: title })}
          </Link>
        ))}
      </nav>
      <UserMenu />
    </div>
  );
}
